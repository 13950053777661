.hide{
    display: none !important;
}
.home-main-container{
    width: 100vw;
    padding-left: 7vw;
    padding-right: 7vw;
    margin-top: 50px;
}
.main-div-img{
    width: 100vw;
    height: 100vh;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}
.main-lbl{
    width: 100vw;
    text-align: center;
    position: absolute;
    top: 5vh;
    font-size: 3rem;
    color: #000000;
    text-shadow:
        -1px -1px 0 #FFF,  
        1px -1px 0 #FFF,
        -1px  1px 0 #FFF,
        1px  1px 0 #FFF;
}