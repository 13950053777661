.loading-main-div{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
}
.loading-img{
    position: relative;
    min-width: 100%;
    min-height: 100%;
    overflow-y: hidden;
}
.loading-alpha{
    opacity: 0.75;
}